export enum GROUP_MEMBER_ROLE {
  OWNER = 'owner',
  MEMBER = 'member',
  MANAGER = 'manager',
}

export enum GROUP_MEMBER_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}

export enum GROUP_MEMBER_COURSE_TYPE {
  COURSE = 'course',
  WORKSHOP = 'workshop',
}

export enum GROUP_MEMBER_COURSE_STATUS {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',
}